import { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import customAxios from "lib/axios";

function ForgotPassword() {
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    console.log(userEmail);
    try {
      const response = await customAxios.post(`users/send-reset-email`, {
        userEmail,
      });
      console.log(response);
      if (response.data.success) {
        setSuccess(t("login:emailSent"));
      } else {
        setError(t("login:emailError"));
      }
    } catch (err) {
      setError(
        err.response?.data?.message ||
          t("login:emailError") ||
          "Error al enviar el correo, intenta nuevamente."
      );
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
      bgcolor="#fff"
    >
      <Box
        maxWidth="420px"
        width="100%"
        p={4}
        borderRadius="8px"
        textAlign="left"
        boxShadow={2}
        sx={{ bgcolor: "background.paper" }}
      >
        <Typography variant="h5" mb={2}>
          {t("login:forgotPassword")}
        </Typography>
        <Typography mb={4}>{t("login:textForgotPassword")}</Typography>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color="primary" mb={2}>
            {success}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            label={t("login:email")}
            variant="outlined"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ py: 1.2 }}
          >
            {t("login:resetPassword")}
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
