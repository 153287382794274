// CreateAccount.tsx
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box, Button, Typography, Modal, Fade } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CreateAccountProps {
  open?: boolean;
  onClose?: () => void;
}

const CreateAccount: React.FC<CreateAccountProps> = ({
  open: propOpen,
  onClose,
}) => {
  const history = useHistory();
  const { t } = useTranslation(["tradingRobots"]);

  // Estado interno para controlar la apertura del modal
  const [open, setOpen] = useState<boolean>(
    propOpen !== undefined ? propOpen : true
  );

  // Sincronizar el estado interno con la prop 'open' si está disponible
  useEffect(() => {
    if (propOpen !== undefined) {
      setOpen(propOpen);
    }
  }, [propOpen]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setOpen(false);
      // Opcional: navegar a otra ruta si no se proporciona 'onClose'
      history.push("/");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={4}
          py={1}
          width={300}
          height={470}
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color="white"
          textAlign="center"
          lineHeight="1.2"
          position="relative"
        >
          <HighlightOffIcon
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <h2>
            {t("CreateAccount.openNew")}
            <br />
            {t("CreateAccount.mt4Account")}
          </h2>
          <Button
            sx={{ my: 2, fontSize: "16px" }}
            variant="contained"
            size="large"
            href="https://go.fpmarkets.com/visit/?bta=37291&nci=5678"
            target="_blank"
          >
            {t("CreateAccount.brokerFP")}
          </Button>
          <Button
            sx={{ my: 2, fontSize: "16px" }}
            variant="contained"
            size="large"
            href="https://go.vantagefx.com/visit/?bta=55092&nci=10826"
            target="_blank"
          >
            {t("CreateAccount.brokerVantage")}
          </Button>
          <Box mt={3}>
            <Typography variant="body1" color="secondary" fontWeight={500}>
              {t("CreateAccount.haveAlreadyAccount")}
            </Typography>
            <Typography
              sx={{
                cursor: "pointer",
                textDecorationLine: "underline",
              }}
              component="a"
              referrerPolicy="no-referrer"
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  history.push("/tradingrobots/LinkAccount");
                }, 100);
              }}
            >
              {t("CreateAccount.linkAccountHere")}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateAccount;
