import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// Frameworks
import {
  Box,
  Typography,
  Modal,
  Fade,
  TextField,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import useAppDispatch from "hooks/useAppDispatch";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
// Custom imports
import { show } from "store/reducers/notifications.reducer";
// Assets
import Logo from "assets/img/logo.png";
import LoginIcon from "@mui/icons-material/Login";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import customAxios from "lib/axios";

const Login = function () {
  const localUserId = localStorage.getItem("user_id");
  const dispatch = useAppDispatch();
  const isUserLoggedIn =
    localUserId && !Number.isNaN(localStorage.getItem("user_id"));
  const [t] = useTranslation(["login"]);
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    // Test mode for front-end development
    // setLoading(true);
    // dispatch(
    //   show({ type: "success", msg: "Sesión iniciada correctamente" })
    // );
    // localStorage.setItem("user_id", "res.data.user_id");
    // setOpen(false);
    // window.location.reload();
    // return;

    customAxios
      .post("/users/login", { email: userName, password })
      .then((res) => {
        if (res.data.success) {
          dispatch(
            show({ type: "success", msg: "Sesión iniciada correctamente" })
          );
          localStorage.setItem("user_id", res.data.user_id);
          setOpen(false);
          window.location.reload();
        } else {
          localStorage.removeItem("user_id");
          dispatch(
            show({ type: "error", msg: "Usuario o contraseña incorrectos" })
          );
        }
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          show({ type: "error", msg: "Error inesperado, contacte con soporte" })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    function onEnter(event: KeyboardEvent) {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        onSubmit();
      }
    }

    if (isUserLoggedIn) {
      document.removeEventListener("keydown", onEnter);
      handleClose();
    } else {
      document.addEventListener("keydown", onEnter);
      handleOpen();
    }

    return () => {
      document.removeEventListener("keydown", onEnter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, password, isUserLoggedIn]);

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableRestoreFocus
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(6px)",
      }}
    >
      <Fade in={open}>
        <Box
          bgcolor="#1A2B47"
          px={4}
          py={1}
          borderRadius="10px"
          display="flex"
          flexDirection="column"
        >
          <Box display="flex" justifyContent="center" my={4}>
            <img src={Logo} alt="tradEAsy Logo" width="200px" />
          </Box>

          <TextField
            label={t("email")}
            color="secondary"
            type="email"
            variant="outlined"
            name="username"
            onChange={(e) => setUserName(e.target.value)}
            value={userName}
            sx={{ my: 2 }}
            InputProps={{
              sx: { color: "secondary.main" },
            }}
            InputLabelProps={{ sx: { color: "secondary.main" } }}
          />

          <TextField
            label={t("password")}
            type={showPassword ? "text" : "password"}
            variant="outlined"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            color="secondary"
            InputProps={{
              sx: { color: "secondary.main" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="secondary"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    size="small"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ sx: { color: "secondary.main" } }}
          />
          <Link
            component={RouterLink}
            to="/forgot-password"
            variant="body2"
            sx={{ cursor: "pointer" }}
            onClick={handleClose}
          >
            {t("forgotPassword")}
          </Link>

          <LoadingButton
            onClick={onSubmit}
            sx={{ my: 3 }}
            loading={loading}
            variant="contained"
            loadingPosition="start"
            startIcon={<LoginIcon />}
          >
            {t("Login")}
          </LoadingButton>

          <Box my={2}>
            <Typography variant="body1" color="secondary" fontWeight={500}>
              {t("noAccount")}
            </Typography>
            <Typography
              component="a"
              color="primary"
              target="_blank"
              referrerPolicy="no-referrer"
              href="https://tradeasy.tech/registro-tradeasy2/"
            >
              {t("signupHere")}
            </Typography>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default Login;
