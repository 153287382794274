import { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import customAxios from "lib/axios";

function ResetPassword() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    if (newPassword !== confirmPassword) {
      setError(t("login:passwordMismatch"));
      return;
    }
    try {
      const response = await customAxios.post("users/reset-password", {
        token,
        newPassword,
      });
      if (response.data.success) {
        setSuccess(t("login:passwordUpdated"));
        // Redirige al login después de unos segundos
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else {
        setError(t("login:updateError"));
      }
    } catch (err) {
      setError(err.response?.data?.message || t("login:updateError"));
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
      bgcolor="#fff"
    >
      <Box
        maxWidth="420px"
        width="100%"
        p={4}
        borderRadius="8px"
        textAlign="left"
        boxShadow={2}
        sx={{ bgcolor: "background.paper" }}
      >
        <Typography variant="h5" mb={2}>
          {t("login:resetPassword")}
        </Typography>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        {success && (
          <Typography color="primary" mb={2}>
            {success}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            required
            label={t("login:newPassword")}
            variant="outlined"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            required
            label={t("login:confirmPassword")}
            variant="outlined"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ py: 1.2 }}
          >
            {t("login:resetPassword")}
          </Button>
        </form>
      </Box>
    </Box>
  );
}

export default ResetPassword;
